module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"de","siteUrl":"https://restaurant-kreta-meitingen.de/","trailingSlash":"always","i18nextOptions":{"fallbackLng":"de","supportedLngs":["de","en"],"redirect":false,"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kreta Restaurant","short_name":"Kreta","description":"Erleben Sie authentische griechische Küche in Meitingen: Frische Speisen, mediterranes Flair, gemütliche Atmosphäre. Buchen Sie jetzt und lassen Sie sich verwöhnen!","start_url":"/","icon":"src/images/icon.png","background_color":"#18191a","theme_color":"#18191a","display":"fullscreen","theme_color_in_head":false,"crossOrigin":"use-credentials","orientation":"portrait","lang":"de","categories":["food"],"dir":"ltr","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"28e12ae45e585711dd78dbacda7b94dc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.restaurant-kreta-meitingen.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3L0SC4W42H"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
